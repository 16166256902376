<template>
  <div>
    <b-form @submit.stop.prevent="formOnSubmit">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-purple"
                @click="showModalDoctor"
              >
                Tambah Dokter
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-table
                striped
                hover
                class="mt-3"
                responsive
                :items="itemCommisionServiceDoctor"
                :fields="fields"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '20%' : '' }"
                  />
                </template>
                <template #cell(number)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(name)="data">
                  {{ data.item.paymentCategory.name }}
                </template>
                <template #cell(doctor_commission)="data">
                  <div class="text-center">
                    {{ data.item.doctor_commission }} <b>({{ data.item.doctor_commission_percent_display }})</b>
                  </div>
                </template>
                <template #cell(hospital_income)="data">
                  <div class="text-center">
                    {{ data.item.hospital_income }} <b>({{ data.item.hospital_income_percent_display }})</b>
                  </div>
                </template>
                <template #cell(price)="data">
                  {{
                    parseInt(data.item.paymentCategory.price).toLocaleString(
                      "id-ID"
                    )
                  }}
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex">
                    <template>
                      <b-button
                        size="sm"
                        class="btn-danger"
                        v-b-tooltip.hover
                        title="Hapus"
                        @click="btnDeleteService(data.item.id)"
                        ><i class="fas fa-trash px-0"></i
                      ></b-button>
                    </template>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </b-form>

    <b-modal
      id="modal-commision-doctor-amount"
      size="md"
      :title="'Besaran Komisi - ' + display.detail"
      hide-footer
    >
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <!-- doctor commission -->
          <b-form-group
            id="input-group-doctor-commission"
            :label="`Komisi Dokter (${isPercent ? '%' : 'Rp'}):`"
            label-for="input-doctor-commission"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  squared
                  @click="btnIsPercentToggle"
                  class="bg-light-primary font-weight-bold text-primary"
                  >{{ isPercent ? "%" : "Rp" }}</b-input-group-text
                >
              </template>
              <b-form-input
                id="input-doctor-commission"
                @keyup="doctorCommissionOnKeyUp"
                v-model="formatedDoctorCommission"
                placeholder="Komisi Dokter..."
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.doctor_commission }}</small>
          </b-form-group>
        </div>
        <div class="col-md-12 col-sm-12">
          <!-- hospital income -->
          <b-form-group
            id="input-group-hospital-income"
            :label="`Pendapatan Rumah Sakit (${isPercent ? '%' : 'Rp'}):`"
            label-for="input-hospital-income"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  squared
                  @click="btnIsPercentToggle"
                  class="bg-light-primary font-weight-bold text-primary"
                  >{{ isPercent ? "%" : "Rp" }}</b-input-group-text
                >
              </template>
              <b-form-input
                id="input-hospital-income"
                @keyup="hospitalIncomeOnKeyUp"
                v-model="formatedHospitalIncome"
                placeholder="Pendapatan Rumah Sakit..."
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.hospital_income }}</small>
          </b-form-group>

          <!-- save -->
          <b-button type="button" @click="saveDataDoctor" variant="primary"
            >Simpan</b-button
          >
          <!-- cancle -->
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>

    <ModalDoctorXPayment @chosenDoctor="chosenDoctor"></ModalDoctorXPayment>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import module from "@/core/modules/CrudModule.js";
import ModalDoctorXPayment from "@/component/general/ModalDoctorXPayment.vue";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    dataItem: Object,
    doctors_id: Array,
  },

  components: {
    Treeselect,
    ModalDoctorXPayment,
  },

  data() {
    return {
      // Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Nama Dokter",
          sortable: true,
        },
        {
          key: "doctor_commission",
          label: "Komisi dokter",
          sortable: true,
        },
        {
          key: "hospital_income",
          label: "Pendapatan Rumah sakit",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // data
      data: {
        price: 0,
        doctor_commission: 0,
        hospital_income: 0,
      },

      display: {
        detail: "",
      },

      error: {
        hospital_income: "",
        doctor_commission: "",
      },
      // Options
      doctors: [],
      // Error
      error: {
        payment_category_id: "",
        doctors_id: "",
        hospital_income: "",
        doctor_commission: "",
      },
      // Mutated
      mutated_form_doctors_id: this.form.doctors_id,
      // Other
      number: 1,
      totalPercentage: 100,
      isPercent: false,
      selectedItem: [],
      itemCommisionServiceDoctor: [],
    };
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString("id-ID");
        } else {
          this.form.price = "";
        }
      },
      get() {
        return this.form.price;
      },
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.doctor_commission = newValue.toLocaleString("id-ID");
        } else {
          this.form.doctor_commission = "";
        }
      },
      get() {
        return this.form.doctor_commission;
      },
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.hospital_income = newValue.toLocaleString("id-ID");
        } else {
          this.form.hospital_income = "";
        }
      },
      get() {
        return this.form.hospital_income;
      },
    },
  },

  methods: {
    async btnDeleteService(id) {
      let a;
      for (a = 0; a < this.selectedItem.length; a++) {
        if (this.selectedItem[a].id == id) {
          this.selectedItem.splice(a, 1);
        }
      }

      // Delete Data
      let result = await module.delete("payment-category-x-doctor/" + id);
      // If Deleted
      if (result) {
        this.selectedItem = [];
        this.getDoctorCommision();
      }
    },

    doctorCommissionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.doctor_commission;
          this.form.hospital_income = this.totalPercentage;
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          console.log(this.form.doctor_commission);
          share -= parseInt(this.form.doctor_commission.replace(/\D/g, ""), 10);
          // share -= parseInt(this.form.doctor_commission, 10);
          this.form.hospital_income = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.hospital_income;
          this.form.doctor_commission = this.totalPercentage;
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          share -= parseInt(this.form.hospital_income, 10);
          // console.log(his.form.hospital_income);
          share -= parseInt(this.form.hospital_income.replace(/\D/g, ""), 10);
          this.form.doctor_commission = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    btnIsPercentToggle() {
      this.totalPercentage = 100;
      this.form.doctor_commission = "";
      this.form.hospital_income = "";
      this.isPercent ? (this.isPercent = false) : (this.isPercent = true);
    },

    hideModalCancel() {
      this.$bvModal.hide("modal-commision-doctor-amount");
      this.$bvModal.show("modal-doctor");
      this.form.doctor_commission = 0;
      this.form.hospital_income = 0;
      console.log(this.form.price,"price")
      this.form.price = parseInt(this.form.price).toLocaleString('id-ID')
    },

    async getDoctorOptions() {
      let response = await module.setDoctorTreeSelect(
        `doctors-doesnt-have-payment-category-commission/${this.$route.params.id}`
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async getDoctorCommision() {
      this.selectedItem = [];
      let response = await module.paginate(
        `payment-category-x-doctor/get-by-payment-category/${this.$route.params.id}`
      );
      // Success
      if (response.data != []) {
        // Set Option Select
        this.itemCommisionServiceDoctor = response.data;

        this.$root.$emit("requestDoctorCommision");
      }
    },

    showModalDoctor() {
      this.$bvModal.show("modal-doctor");
    },

    chosenDoctor(value) {
      console.log();
      if (this.itemCommisionServiceDoctor.findIndex((x) => x.doctor_id == value.id) < 0) {
        this.$bvModal.hide("modal-doctor");
        this.$bvModal.show("modal-commision-doctor-amount");
        console.log(value,"vall =================");
        let datas = value;
        this.form.doctors_id = datas.id;
        this.display.detail = datas.name;
      }
    },

    saveDataDoctor() {
      console.log(this.form);
      this.form.is_percent = this.isPercent;

      if (this.isPercent == true) {
        let amount =
          parseInt(this.form.hospital_income, 10) +
          parseInt(this.form.doctor_commission, 10);
        if (amount > 100) {
          this.form.doctor_commission = 0;
          this.form.hospital_income = 0;
          this.$bvModal.hide("modal-commision-doctor-amount");

          this.error.hospital_income = "Jumlah tidak boleh melebihi 100"
          this.error.doctor_commission = "Jumlah tidak boleh melebihi 100"
        }
      } else {
        let intPrice = parseInt(this.form.price.replace(/\D/g, ""), 10);
        let intDoctorCommission = parseInt(
          this.form.doctor_commission.replace(/\D/g, ""),
          10
        );
        let intHospitalIncome = parseInt(
          this.form.hospital_income.replace(/\D/g, ""),
          10
        );

        let amount = intDoctorCommission + intHospitalIncome;
        if (amount > intPrice) {
          this.form.doctor_commission = 0;
          this.form.hospital_income = 0;
          this.isPercent = false;
          this.form.is_percent = false;
          this.error.hospital_income = "Jumlah tidak boleh melebihi harga"
          this.error.doctor_commission = "Jumlah tidak boleh melebihi harga"
          this.$bvModal.hide("modal-commision-doctor-amount");

        }
      }

      this.formOnSubmit();
    },

    async formOnSubmit() {
      console.log(this.form.price);

      this.form.price = parseInt(this.form.price.replace(/\D/g, ""),10)
      if (!this.isPercent) {
        // let intPrice = parseInt(this.form.price.replace(/\D/g, ""),10)
        let intPrice = this.form.price
        let intDoctorCommission = parseInt(
          this.form.doctor_commission.replace(/\D/g, ""),
          10
        );
        let intHospitalIncome = parseInt(
          this.form.hospital_income.replace(/\D/g, ""),
          10
        );
        if (intPrice < intDoctorCommission || intPrice < intHospitalIncome) {
          Swal.fire(
            "Gagal",
            "Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga",
            "warning"
          );
        } else {
          this.error.hospital_income = ""
          this.error.doctor_commission = ""
          this.actualFormSubmit();

        }
      } else {
        this.error.hospital_income = ""
        this.error.doctor_commission = ""

        this.actualFormSubmit();
      }
    },

    async actualFormSubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        "payment-category-x-doctor/add"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.selectedItem = [];
        this.hideModalCancel()
        this.getDoctorCommision();
      }
    },

    btnIsPercentToggle() {
      this.error.doctor_commission = "";
      this.error.hospital_income = "";
      this.totalPercentage = 100;
      this.form.doctor_commission = "";
      this.form.hospital_income = "";
      this.isPercent ? (this.isPercent = false) : (this.isPercent = true);
    },

    doctorCommisionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          console.log("persen ini =============");
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.doctor_commission;
          this.form.hospital_income = this.totalPercentage;
        } else {
          console.log(" engga persen ini =============");
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          share -= parseInt(this.form.doctor_commission.replace(/\D/g, ""), 10);
          this.form.hospital_income = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          console.log("persen ini =============");
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.hospital_income;
          this.form.doctor_commission = this.totalPercentage;
        } else {
          console.log("engga persen ini =============");
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          share -= parseInt(this.form.hospital_income.replace(/\D/g, ""), 10);
          this.form.doctor_commission = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    doctorOnSelect(evt) {
      let found = this.doctors.findIndex((x) => x.id == evt.id);
      this.doctors_id.push({
        number: this.number++,
        name: this.doctors[found].label,
      });
    },

    doctorOnDeselect(evt) {
      let found = this.doctors_id.findIndex((x) => x.id == evt.id);
      let index = this.doctors_id.indexOf(this.doctors_id[found]);
      this.doctors_id.splice(index, 1);
    },

    btnRemoveDoctorOnClick(id) {
      let found = this.doctors_id.findIndex((x) => x.id == id);
      let index = this.doctors_id.indexOf(this.doctors_id[found]);
      this.doctors_id.splice(index, 1);
      index = this.mutated_form_doctors_id.indexOf(id);
      this.mutated_form_doctors_id.splice(index, 1);
    },

    resetSelectedDoctor() {
      this.doctors_id = [];
    },
  },

  watch: {
    mutated_form_doctors_id: function (newVal) {
      if (newVal.length == 0) this.resetSelectedDoctor();
    },
  },

  mounted() {
    this.getDoctorOptions();
    this.getDoctorCommision();

    this.$root.$on("requestAfterDeleteDoctorCommission", (value) => {
      this.getDoctorCommision();
    });
  },
};
</script>

<style scoped>
.bg-light-primary:hover {
  background-color: #e1f0ffa6 !important;
}
</style>